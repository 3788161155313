/* You can add global styles to this file, and also import other style files */
@charset "utf-8";

// Import a Google Font
@import url('https://fonts.googleapis.com/css?family=Nunito:400,700');

// Set your brand colors
$light-blue: #e5eff7;
$dull-blue: #98a6b5;
$dull-blue-invert: #fff;
$dull-blue-light: #f3f5f6;
$dull-blue-dark: #485665;

// Update Bulma's global variables
// $family-sans-serif: "Nunito", sans-serif;
$primary: $light-blue;
// $widescreen-enabled: false;
// $fullhd-enabled: false;

// // Update some of Bulma's component variables
// $body-background-color: $beige-lighter;
// $control-border-width: 2px;
// $input-border-color: transparent;
// $input-shadow: none;

$custom-colors: (
  'secondary': (
    $dull-blue,
    $dull-blue-invert,
    $dull-blue-light,
    $dull-blue-dark,
  ),
);

$body-rendering: auto;

$am-border-color: #98a6b5;

// Import only what you need from Bulma
@import '../node_modules/bulma/sass/utilities/_all.sass';
@import '../node_modules/bulma/sass/base/_all.sass';
@import '../node_modules/bulma/sass/elements/_all.sass';
@import '../node_modules/bulma/sass/form/_all.sass';
@import '../node_modules/bulma/sass/components/_all.sass';
@import '../node_modules/bulma/sass/layout/_all.sass';
@import '../node_modules/bulma/sass/grid/_all.sass';
@import '../node_modules/bulma/sass/helpers/_all.sass';

// Fix Bulma specific

// let work with angular material form
table {
  th,
  td {
    vertical-align: baseline;
    &:not([align]) {
      text-align: center;
    }
  }
}

// pagination disabled to class
.pagination-previous,
.pagination-next,
.pagination-link {
  background-color: $white;
  &[disabled] {
    background-color: $pagination-disabled-background-color !important;
    border-color: $pagination-disabled-border-color !important;
    box-shadow: none !important;
    color: $pagination-disabled-color !important;
    opacity: 0.5 !important;
  }
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  background-color: #f5ffff;
  overflow: auto;

  &.is-edit {
    background-color: #fff1e8;
  }
}

.is-am-bordered,
.button.is-am-bordered,
.button:hover.is-am-bordered,
.image.is-bordered,
.notification.is-bordered {
  border: 1px solid $am-border-color;
  &.radius {
    border-radius: 5px;
  }
}

.is-am-hr-border {
  height: 1px;
  background-color: $am-border-color;
}

.image .fit-image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  &.is-contain img {
    object-fit: contain;
    font-family: 'object-fit: contain;';
  }

  &.is-cover img {
    object-fit: cover;
    font-family: 'object-fit: cover;';
  }
}

img.is-bordered {
  border: 2px solid #3b5465;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.buttons {
  app-button {
    margin-bottom: 0.5rem;
    .button {
      margin-bottom: 0;
    }
  }
  app-button:not(:last-child) {
    margin-right: 0.5rem;
  }
}

@font-face {
  font-family: 'icon-heart';
  src: url('assets/fonts/icon-heart.eot?36u02x');
  src: url('assets/fonts/icon-heart.eot?36u02x#iefix')
      format('embedded-opentype'),
    url('assets/fonts/icon-heart.ttf?36u02x') format('truetype'),
    url('assets/fonts/icon-heart.woff?36u02x') format('woff'),
    url('assets/fonts/icon-heart.svg?36u02x#icon-heart') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icon-heart' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-not-favorite:before {
  content: '\e902';
  font-size: 24px;
  color: #4a4a4a;
}
.icon-favorite:before {
  content: '\e903';
  font-size: 24px;
  color: #df3f3e;
}
